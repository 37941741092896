import React, { useState, useEffect } from 'react';
import './Bodega.css';

import { Card, 
    CardHeader, 
    CardBody, 
    CardFooter, 
    SimpleGrid, 
    Heading, 
    Text, 
    Flex,
    CircularProgress, 
    CircularProgressLabel,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    Box,
    Divider
  } from '@chakra-ui/react'
  
  import { RepeatIcon } from '@chakra-ui/icons'


const Bodega = ({ buque, index, actualizar }) => {
  
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses van de 0 a 11
    const day = String(today.getDate()).padStart(2, '0');
    
    const formattedDate = `${year}/${month}/${day}`; // Resultado: "2024-08-22"

  
  return (
    <Box className="bodega" background='#f3f3f3'>
      <div className='nombre-barco-bodega'><Heading as='h2' size='xl' >{buque.id + ' - ' +buque.descrp}</Heading></div>
        <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(500px, 1fr))'>
                <Card background="#b4d0e5" shadow='lg'  transition="all 0.2s ease-in-out"
                    _hover={{
                        boxShadow: 'xl',
                        transform: 'translateY(-4px)',
                    }}>
                    <CardHeader>
                        <Heading size='lg' >Estado de Bodega</Heading>
                    </CardHeader>
                    <CardBody p='10px'>
                        <Flex>
                            <CircularProgress value={(buque.ton_dia * 100) / buque.cap_barco} color='teal.600' size='13rem'>
                                <CircularProgressLabel>{( (buque.ton_dia * 100) / buque.cap_barco).toFixed(0) > 100 ? 100 + '%' : ((buque.ton_dia * 100) / buque.cap_barco).toFixed(0) + '%'}</CircularProgressLabel>
                            </CircularProgress>
                            <Card background="#FFFFFF" ml='40px' p='10px' shadow='lg'>
                                <CardBody>
                                    <Flex>
                                        <Heading as='h4' size='md' mb='40px'>Capacidad de Bodega:</Heading> 
                                        <Text fontSize='lg' ml='10px'>{buque.cap_barco}</Text>
                                    </Flex>
                                    <Flex>
                                        <Heading as='h4' size='md' mb='40px'>Total Capturado: </Heading> 
                                        <Text fontSize='lg' ml='10px'>{buque.ton_dia}</Text>
                                    </Flex>
                                    <Flex>
                                        <Heading as='h4' size='md'>Restan: </Heading> 
                                        <Text fontSize='lg' ml='10px'>{(buque.cap_barco - buque.ton_dia).toFixed(2) < 0 ? 0 : (buque.cap_barco - buque.ton_dia).toFixed(2)}</Text>
                                    </Flex>
                                </CardBody>
                            </Card>
                        </Flex>
                        
                        <Flex mt='30px'>
                            <Heading as='h4' size='md' ml='10px'>Promedio Captura Diario:</Heading> 
                            <Text fontSize='lg' ml='10px'>{(buque.ton_dia/buque.dias_producidos).toFixed(2)}</Text>
                        </Flex>
                        <Flex mt='5px'>
                            <Heading as='h4' size='md' ml='10px'>Toneladas capt. en últ. Parte:</Heading> 
                            <Text fontSize='lg' ml='10px'>{(buque.tons_ultParte)} TN</Text>
                        </Flex>
                    </CardBody>
                    <CardFooter width="100%">
                        <Flex justify="space-between" align="center" width="100%">
                            <Text as="i">Ultima actualización: {buque.ult_act.replace("T", " ").replace("Z", " ")}</Text>
                            <RepeatIcon boxSize={6} cursor="pointer" onClick={actualizar}></RepeatIcon>
                        </Flex>
                    </CardFooter>
                </Card>
                <Card background="#b4d0e5" transition="all 0.2s ease-in-out"
                    _hover={{
                        boxShadow: 'xl',
                        transform: 'translateY(-4px)',
                    }}>
                    <CardHeader>
                        <Heading size='lg'>Datos Marea</Heading>
                    </CardHeader>
                    <CardBody>
                        <Card background="#FFFFFF" p='10px' shadow='lg'>
                                <CardBody>
                                    <Flex mb='20px'  justifyContent="space-between">
                                        <Heading as='h4' size='md'>N° Marea Anual:</Heading> 
                                        <Text fontSize='lg' ml='10px'>{buque.marea_anual}</Text>
                                        
                                        <Heading as='h4' size='md'>N° Marea Histórica:</Heading> 
                                        <Text fontSize='lg' ml='10px' >{buque.marea_hist}</Text>
                                    </Flex>
                                    <Flex mb='20px'>
                                        <Heading as='h4' size='md'>Fecha Salida:</Heading> 
                                        <Text fontSize='lg' ml='10px'>{buque.fechSal}</Text>
                                    </Flex>
                                    
                                    <Flex>
                                        <Stat>
                                            <StatLabel fontSize='xl'>Dias de Navegación</StatLabel>
                                            <StatNumber fontSize='2rem'>{buque.position}</StatNumber>
                                            <StatHelpText fontSize='lg'>{buque.fechSal.replace(/-/g, "/")} - {buque.fechEnt.replace(/-/g, "/")}</StatHelpText>
                                        </Stat>
                                        <Stat>
                                            <StatLabel fontSize='xl'>Dias de Producción</StatLabel>
                                            <StatNumber fontSize='2rem'>{buque.dias_producidos}</StatNumber>
                                            <StatHelpText fontSize='lg'>{buque.fecha_primerParte.replace(/-/g, "/")} - {buque.fecha_ultParte.replace(/-/g, "/")}</StatHelpText>
                                        </Stat>
                                    </Flex>
                                </CardBody>
                            </Card>
                    </CardBody>
                    <CardFooter width="100%">
                        <Flex justify="space-between" align="center" width="100%">
                            <Text as="i">Ultima actualización: {buque.ult_act.replace("T", " ").replace("Z", " ")}</Text>
                            <RepeatIcon boxSize={6} cursor="pointer" onClick={actualizar}></RepeatIcon>
                        </Flex>
                    </CardFooter>
                </Card>
                <Card background="#19273a" shadow='lg' transition="all 0.2s ease-in-out"
                    _hover={{
                        boxShadow: 'xl',
                        transform: 'translateY(-4px)',
                    }}>
                    <CardHeader>
                        <Heading size='lg' color='white'>{buque.estado === 0 ? 'Entrada Estimada' : 'Entrada'}</Heading>
                    </CardHeader>
                    <CardBody>
                        <Card background="#FFFFFF" shadow='lg'>
                                <CardBody>
                                    {buque.estado === 1 &&
                                        <Box>
                                            <Heading as='center' size='lg'>Fecha Entrada</Heading> 
                                            <Text as='center' fontSize='2rem'>{buque.estado === 1 ? buque.fechEnt : new Date(new Date().setDate(new Date().getDate() + ( buque.viajando > 0 ? (buque.viajando) : (Math.ceil((buque.cap_barco - buque.ton_dia) / (buque.ton_dia / buque.dias_producidos))) ))).toLocaleDateString('es-ES', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric'
                                              })}</Text>
                                            <hr style={{ border: 'none', height: '1px', backgroundColor: '#ccc', margin: '10px 0' }} />
                                            <Heading as='center' size='lg'>BUQUE EN PUERTO</Heading> 
                                        </Box>

                                    }

                                    {buque.estado === 0 &&
                                    <Box>
                                        <Heading as='center' size='lg'>Fecha Estimada de Entrada</Heading> 
                                        <Text as='center' fontSize='2rem'>{buque.estado === 1 ? buque.fechEnt : new Date(new Date().setDate(new Date().getDate() + ( buque.viajando > 0 ? (buque.viajando) : (Math.ceil((buque.cap_barco - buque.ton_dia) / (buque.ton_dia / buque.dias_producidos))) ))).toLocaleDateString('es-ES', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric'
                                              })}</Text>
                                        <hr style={{ border: 'none', height: '1px', backgroundColor: '#ccc', margin: '10px 0' }} />
                                        <Heading as='center' size='lg'>Restan dias</Heading> 
                                         <Text as='center' fontSize='2rem'>{buque.estado === 1 ? 0 : buque.viajando > 0 ? (buque.viajando) : Math.ceil((buque.cap_barco - buque.ton_dia) / (buque.ton_dia / buque.dias_producidos))}</Text>
                                    </Box>
                                    }
                                    
                                    
                                </CardBody>
                            </Card>
                    </CardBody>
                    <CardFooter width="100%">
                        <Flex justify="space-between" align="center" width="100%">
                            <Text as="i"  color="white">Ultima actualización: {buque.ult_act.replace("T", " ").replace("Z", " ")}</Text>
                            <RepeatIcon boxSize={6} cursor="pointer" onClick={actualizar} color="white"></RepeatIcon>
                        </Flex>
                    </CardFooter>
                </Card>
                
            </SimpleGrid>
            <Divider 
                border="none" 
                height="2px" 
                backgroundColor="#ccc" 
                mt='20px'
                />
    </Box>
  );
};


export default Bodega;
