import React, { useState, useEffect } from 'react';
import Bodega from '../bodega/Bodega';
import BreadcrumbNav from '../nav/BreadcrumNav';
import {
    Box
  } from '@chakra-ui/react'
import './Track.css';

const Track2 = ({boats, actualizar, ocultarBarra}) => {

  const [verDetalleBarco, setVerDetalleBarco] = useState(false);
  const [barcoSeleccionado, setBarcoSeleccionado] = useState({});
  
  const onClickDetailsBoat = (boat, ver) => {
    setBarcoSeleccionado(boat);
    setVerDetalleBarco(ver);
  };

  return (
    <Box className="track" background='#f3f3f3'>
      {!ocultarBarra &&
      <Box h='10%'>
        <BreadcrumbNav seleccionado={'1'} tipoUser ={'G'} />
      </Box>
      }
      <Box overflowY='auto'>
        {boats.sort((a, b) => a.estado - b.estado).map((boat, index) => (
          <Box key={boat.id}  onClick= {() => onClickDetailsBoat(boat, true)}>
            <Bodega className='track-container' key={boat.id} buque={boat} index= {index} actualizar = {actualizar}/>
          </Box>
        ))}
      </Box>
      
    </Box>
  );
};



export default Track2;
