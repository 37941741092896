import React, { useState, useEffect  } from 'react';
import './BoatDetails.css';
import { Image
} from '@chakra-ui/react'


const BoatDetails = ({ buque, ocultar }) => {
  

    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.key === 'Escape') {
            ocultar({}, false);
          }
        };
    
        document.addEventListener('keydown', handleKeyDown);
        // Limpia el evento de escucha cuando el componente se desmonta
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, [ocultar]);

  return (
    <div className="boatDetails">
      <div>
        <div className='container-escape-btn-BD'>
          <Image
            borderRadius='full'
            boxSize='90px'
            src={`/images/${buque.id}.jpg`}
            alt='barcoimg'
          />
          <span className='boatDetails-titles-D'>{buque.descrp}</span>
          <div className='escape-btn-BD' onClick={() => ocultar({}, false)}>X</div>
        </div>

        <hr style={{ border: 'none', height: '2px', backgroundColor: '#000000', margin: '10px 0' }} />

        <div className='boatDetails-item'>
          <span className='boatDetails-titles'>ID:</span>
          <span className='boatDetails-data'>{buque.id}</span>
        </div>
        <div className='boatDetails-item'>
          <span className='boatDetails-titles'>N° Marea Anual:</span>
          <span className='boatDetails-data'>{buque.marea_anual}</span>
        </div>
        <div className='boatDetails-item'>
          <span className='boatDetails-titles'>N° Marea Histórica:</span>
          <span className='boatDetails-data'>{buque.marea_hist}</span>
        </div>
        <div className='boatDetails-item'>
          <span className='boatDetails-titles'>Fecha de Salida:</span>
          <span className='boatDetails-data'>{new Date(buque.fechSal).toLocaleDateString('es-ES', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric'
                                              })}</span>
        </div>

        <hr style={{ border: 'none', height: '2px', backgroundColor: '#ccc', margin: '20px 0' }} />
        
        <div className='boatDetails-item'>
          <span className='boatDetails-titles'>Estado:</span>
          <span className='boatDetails-data'>{buque.estado === 0 ? 'Navegando' : buque.estado === 2 ? 'Volviendo' : 'Puerto'}</span>
        </div>
        <div className='boatDetails-item'>
          <span className='boatDetails-titles'>Días Navegados:</span>
          <span className='boatDetails-data'>{buque.position}</span>
        </div>

        <div className='boatDetails-item'>
          <span className='boatDetails-titles'>{buque.estado === 1 ? "Fecha Entrada:" : "Fecha Estimada de Entrada:"}</span>
          <span className='boatDetails-data'> {buque.estado === 1 ? new Date(buque.fechEnt).toLocaleDateString('es-ES', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric'
                                              }) : new Date(new Date().setDate(new Date().getDate() + ( buque.viajando > 0 ? (buque.viajando) : (Math.ceil((buque.cap_barco - buque.ton_dia) / (buque.ton_dia / buque.dias_producidos))) ))).toLocaleDateString('es-ES', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric'
                                              })}</span>
        </div>

        <div className='boatDetails-item'>
          <span className='boatDetails-titles'>Días Restantes:</span>
          <span className='boatDetails-data'> {buque.estado === 1 ? 0 : buque.viajando > 0 ? (buque.viajando) : Math.ceil((buque.cap_barco - buque.ton_dia) / (buque.ton_dia / buque.dias_producidos))}</span>
        </div>

        <hr style={{ border: 'none', height: '2px', backgroundColor: '#ccc', margin: '20px 0' }} />

        <div className='boatDetails-item'>
          <span className='boatDetails-titles'>Fecha Primer Parte de Producción:</span>
          <span className='boatDetails-data'>{new Date(buque.fecha_primerParte).toLocaleDateString('es-ES', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric'
                                              })}</span>
        </div>
        <div className='boatDetails-item'>
          <span className='boatDetails-titles'>Días Producidos:</span>
          <span className='boatDetails-data'>{buque.dias_producidos}</span>
        </div>
        <div className='boatDetails-item'>
          <span className='boatDetails-titles'>Fecha último Parte de Producción:</span>
          <span className='boatDetails-data'>{new Date(buque.fecha_ultParte).toLocaleDateString('es-ES', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric'
                                              })}</span>
        </div>
        <div className='boatDetails-item'>
          <span className='boatDetails-titles'>Toneladas capt. en últ. Parte:</span>
          <span className='boatDetails-data'>{(buque.tons_ultParte)} TN</span>
        </div>
        <div className='boatDetails-item'>
          <span className='boatDetails-titles'>Promedio Diario:</span>
          <span className='boatDetails-data'>{(buque.ton_dia/buque.dias_producidos).toFixed(2)} TN</span>
        </div>
        <div className='boatDetails-item'>
          <span className='boatDetails-titles'>Capacidad del barco:</span>
          <span className='boatDetails-data'>{buque.cap_barco} TN</span>
        </div>
        <div className='boatDetails-item'>
          <span className='boatDetails-titles'>Toneladas al Dia:</span>
          <span className='boatDetails-data'>{buque.ton_dia} TN</span>
        </div>

        <hr style={{ border: 'none', height: '2px', backgroundColor: '#ccc', margin: '20px 0' }} />

        <div className='boatDetails-item-super'>
          <span className='boatDetails-titles'>Toneladas Restantes:</span>
          <span className='boatDetails-data'>{(buque.cap_barco - buque.ton_dia).toFixed(2)} TN</span>
        </div>
      </div>   
    </div>
  );
};

export default BoatDetails;
