import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Boat.css';
import barco from '../../images/icono-barco-track.png'
import barcoCompleto from '../../images/icono-barco-track-Completo.png'
import { Flex,
  Box,
  Heading,
  Image,
  Tooltip
  } from '@chakra-ui/react'

const Boat = ({ buque, index }) => {

/* 
{`linear-gradient(0deg, transparent 33.33%, rgba(57, 144, 179, 0.1) 33.33%, rgba(0, 0, 0, 0.1) 66.66%, transparent 66.66%), ${
          index % 2 === 0 ? '#00897B' : '#b4d0e5'
        }`}
(((Math.ceil((buque.cap_barco - buque.ton_dia) / (buque.ton_dia / buque.dias_producidos))) / 20) * 100) 

*/
  const positionPercentage = buque.estado === 0 ? 
                                                  buque.viajando <= 2 || buque.ultimoParte === 'S' || buque.ton_dia >= buque.cap_barco ? 
                                                                      5 
                                                                      : buque.ton_dia > (buque.cap_barco/2) ? 
                                                                                                              ((((buque.ton_dia - (buque.cap_barco/2)) * 100) / (buque.cap_barco/2)) - 100) * -1
                                                                                                            : (((buque.ton_dia) / (buque.cap_barco/2)) * 100) 
                                               : -3
 
  
  return (
    <Box w='100%'
        h='100%'
        background= '#2c8edd'
        backgroundImage='fondo2.png'
        backgroundPosition="bottom" // Posición de la imagen
        backgroundRepeat="no-repeat"
        backgroundSize="100% 90%"  
        position="relative"
        boxShadow="inset 0 4px 10px rgba(0, 0, 0, 0.2)" // Sombra interna
        >

      <Box position="absolute"
           top="50%"
           transform="translate(-50%, -50%)"
           left={`${positionPercentage >= 94 ? 94 : positionPercentage - 1}%`}
           >
            <Flex>
                  { positionPercentage > 0 &&
                        <Box
                          display="flex"
                          alignItems="flex-end"  // Alinea el contenido al fondo
                          fontSize="25px"
                          fontWeight="700"
                          marginBottom='10px'
                          >
                          {buque.viajando > 0 ? (buque.viajando) : buque.ultimoParte === 'S' || buque.ton_dia >= buque.cap_barco ? 2 : Math.ceil((buque.cap_barco - buque.ton_dia) / (buque.ton_dia / buque.dias_producidos))}
                        </Box>
                  }
                <Tooltip label={buque.descrp} placement='left-start'>
                  <Image maxWidth='none' width="200px" src={ buque.estado !== 0 ? `/imagesTrack/${buque.id}Alt.png` : buque.ton_dia >= (buque.cap_barco/2) ? `/imagesTrack/${buque.id}.png` : `/imagesTrack/${buque.id}Alt.png`} alt="Boat" /> 
                </Tooltip>    
            </Flex>
      </Box>
      <Box display="flex"
           justifyContent="center"
           alignItems="center"
           height="100%"
      >
          <Box fontSize="35px"
                letterSpacing= "5px"  
                color= 'gray'//"#dc280b"
                fontWeight= "bolder"
                textDecoration= "none solid rgb(68, 68, 68)"
                fontStyle= "normal"
                //textShadow="2px 2px 4px rgba(0, 0, 0, 0.5)" // Sombra para efecto 3D
          > 
            {buque.descrp} 
          </Box>
      </Box>
    </Box>
  );
};

Boat.propTypes = {
  id: PropTypes.string,
  descrp: PropTypes.string,
  src: PropTypes.string,
  position: PropTypes.number,
  fechSal: PropTypes.string,
  fechEnt: PropTypes.string,
  estado: PropTypes.number, //Sale=1 / Vuelve=-1 / Puerto=0
  completo: PropTypes.bool,
  ton_dia: PropTypes.number,
  cap_barco: PropTypes.number,
  dias_producidos: PropTypes.number,
};

export default Boat;
